import Vue from 'vue';
import {request, setAuthInHeader} from "@/api/request";
// import {req} from "vuelidate/src/validators/common";


Vue.prototype.$api = {
    $auth: { // 계정 로그인 / 회원가입 api
    },
    $handler: {
        authHeader : setAuthInHeader
    },
    $member: {
        getMentor(params) {
            return request.get(`userinfo/mentor`, params).then(({data}) => data);
        },
    }
};

export default Vue.prototype.$api;
