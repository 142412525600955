<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
    import MobileDetect from "mobile-detect";
    export default {
        name: 'App',
        mixins: [],
        components: {
        },
        props: {
        },
        data() {
            return{
            }
        },
        beforeRouterEnter() {},
        beforeCreate() {
            let md = new MobileDetect(window.navigator.userAgent);
            if(md.mobile() === null){
                location.href="http://www.fatherhood.kr"+this.$route.path;
            }
        },
        created() {

        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{

        },
        watch: {

        },
    }
</script>
</script>
<style lang="scss">
  @import "./assets/scss/index.scss";
</style>
