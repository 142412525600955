<template>
    <div class="wrap">
        <div class="section1">
                <div class="main_logo">
                    <div class="logo_ami"><img src="@/assets/image/ami_logo_x2.png" alt=""></div>
<!--                    <div class="logo_lotte"><img src="@/assets/image/lotte_logo_x2.png" alt=""></div>-->
<!--                    <div class="logo_love"><img src="@/assets/image/love_logo_x2.png" alt=""></div>-->
                </div>



        </div>
        <div class="ami_box">
            아미단?
        </div>
        <div class="ami_description">
            <div class="main_content ami_content">
                아미단은 <span class="fw700">아이들의 미래와 꿈을 응원하는 아빠미소멘토단</span>입니다. <br>
                아미단은 아이들의 물음에 언제 어디서나 응답할 준비를 하고 있어요.<br>
                그래서 아미단은 ‘응응단’이란 별칭도 있답니다.<br>
                아이들을 ‘응원하고‘ 언제든 ‘응답하는’ 멘토단이란 뜻이죠.<br>
                아미단은 풍부한 경험과 따뜻한 ‘아빠미소’를 장착한 멘토들로 <br>
                운영됩니다.<br>
                소중한 마음들이 모여 만들어진 사회적 자산(social capital),<br>
                눈에 보이지 않는 그 자산이 아이들의 마음속에 들어가<br>
                ‘꿈을 키우는 힘’이 되길 바랍니다
            </div>
        </div>
        <div class="section2">
            <div class="main_content">
                <div class="title1"></div>
                <div class="dream_act dream_cookie">
                    <div class="dream_act_innerBox">
                        <div class="dream_act_icon"></div>
                        <div class="dream_txtwrap">
                            <div class="dream_act_title">과자로꿈짓기</div>
                            <div class="dream_act_content">‘너의 꿈을 맛있게 표현해보렴’
                            </div>
                        </div>
                    </div>
                    <div class="dream_pic"></div>
                </div>
                <div class="dream_act dream_letter">
                    <div class="dream_act_innerBox">
                        <div class="dream_act_icon"></div>
                        <div class="dream_txtwrap">

                            <div class="dream_act_title">꿈편지</div>
                            <div class="dream_act_content">‘너의 꿈을 말해줘’ <br>
                                아빠미소멘토단에게 전하는 아이들의 꿈이야기
                            </div>
                        </div>
                    </div>
                    <div class="dream_pic"></div>
                </div>
                <div class="dream_act dream_camp">
                    <div class="dream_act_innerBox">
                        <div class="dream_act_icon"></div>
                        <div class="dream_txtwrap">
                            <div class="dream_act_title">꿈캠프</div>
                            <div class="dream_act_content">‘아빠미소멘토단과 함께하는 꿈캠프’
                            </div>
                        </div>
                    </div>
                    <div class="dream_pic"></div>
                </div>


            </div>
        </div>
        <member-slide-component></member-slide-component>
        <div class="section4">
            <div class="main_content s4">
                <div class="title3"></div>
                <div class="s3_box">
                    <div class="s3_box_txt1">아이 하나 키우는데 온 마을이 필요하다 - 아프리카 속담</div>
                    <div class="s3_box_txt2">혼자서 이룰 수 있는 꿈은 많지 않습니다.
                        아이들이 갖는 <br>꿈에 대한 여러가지 고민을 ‘아빠미소멘토단’이 되어 <br>
                        아이들의 꿈을 찾는 여정에 함께해 주세요
                    </div>

                    <button class="download_btn" @click="moveStore()"> 앱 다운로드</button>
                </div>
            </div>
        </div>
        <div class="m_footer">
            <div class="main_content">
                <div class="fm">
                    <div class="main_logo l_white">
                        <div class="logo_ami l_white"><img src="@/assets/image/ami_logo_white_x2.png" alt=""></div>
<!--                        <div class="logo_lotte l_white"><img src="@/assets/image/lotte_logo_white_x2.png" alt=""></div>-->
<!--                        <div class="logo_love l_white"><img src="@/assets/image/love_logo_white_x2.png" alt=""></div>-->
                    </div>
                    <div class="footer_name">사단법인 함께하는아버지들</div>
                    <div class="footer_addr">
                        서울특별시 중구 퇴계로 18길10 호경빌딩 301호 <br>
                        전화 02-415-7955  |  팩스 0504-191-4159 <br>
                        mail@fathers.or.kr
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
    // @ is an alias to /src
    import MemberSlideComponent from "@/components/Member/MemberSlideComponent";

    export default {
        name: 'Home',
        components: {
            MemberSlideComponent
        },
        mounted() {},
        data() {
            return {}
        },
        methods: {
            moveStore() {
                var uagent = navigator.userAgent.toLowerCase();
                if (uagent.search("iphone") > -1 || uagent.search('ipad') > -1) {
                    location.href = 'https://itunes.apple.com/app/id1519133075?mt=8'
                }else{
                    location.href = 'https://play.google.com/store/apps/details?id=com.dgmonglab.papas'
                }

            },
        },
    }
</script>
