import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes';
import VueJWT from 'vuejs-jwt'



const router = new VueRouter({
  mode: "history",
  routes: routes
});
// 라우터 정의
Vue.use(VueRouter);

export default router;
