<template>
    <div class="section3_bg">
        <div class="main_content section3_main">
            <div class="title2"></div>
            <swiper class="swiper" :options="swiperOptions">
                <swiper-slide v-for="(item, index) in mentorList" :key="index">
                    <div class="swiper_inner_box">
                        <div class="slide_pic">
                            <img v-if="item.Profile[0].org_url !== ''" :src="`${item.Profile[0].org_url}?${setImageOptions(216,216,'jpg')}`"
                                 class="slide_img" alt="">
                        </div>
                        <div class="slide_name">{{item.mb_name}}</div>
                        <div class="slide_job" v-html="item.mb_belong"></div>
                        <div class="slide_job" v-html="item.mb_job"></div>
                    </div>
                    <div class="slide_description">
                        <div class="slide_memo" v-html="memoReturn(item.mb_memo)"></div>
                    </div>
                </swiper-slide>
            </swiper>
            <div class="swiper-button-prev btn_prev" slot="button-prev"></div>
            <div class="swiper-button-next btn_next" slot="button-next"></div>
        </div>
    </div>
</template>

<script>
    import imageOption from "@/mixins/imageOption";
    import {Swiper, SwiperSlide,} from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'

    export default {
        name: "MemberSlideComponent",
        mixins:[imageOption],
        components: {
            Swiper,
            SwiperSlide

        },
        mounted() {
            // this.swiper.slideTo(3, 1000, false);
        },
        created() {
            this.$api.$member.getMentor({opf:1,ip:0,di:1}).then(res => res.Data.Result).then(res => {
                this.mentorList = res.List;
                for(;this.mentorList.length %3 !== 0;){
                    this.mentorList.push({mb_name:'',mb_job:'',mb_belong:'',mb_memo:'',Profile:[{org_url:''}]})
                }
            })
        },
        data() {
            return {
                swiperOptions: {
                    slidesPerView: 1,
                    slidesPerColumn: 3,
                    spaceBetween: 10,

                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    // Some Swiper option/callback...

                },
                mentorList: [],
            }
        },
        methods : {
            memoReturn(target){
                if(!target) {
                    return '';
                }
                return target.replace(/\n/gi,"<br/>").replace(/ /gi,"&nbsp;");
            }
        }
    }
</script>

<style scoped>

</style>
