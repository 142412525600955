function setViewResolver(component) {
    return require('../views/' + component).default;
}
const layout = {
    render(h) { return h('router-view') }
}

export default [
    {
        path: '/',
        component: setViewResolver('Home'),
        name : 'Home',
        meta : { name : 'Main'},
    },
]